<template>
  <CRow>
    <TblLateCount/>
    <CCol>
      <CCard>
        <CCardHeader class="bg-yellow"
          ><strong>สรุปรายชื่อนักเรียนมาสาย/ ขาดแถว</strong>
        </CCardHeader>
        <CCardBody v-if="rows">
          <!---------FILTER AREA---------->
          <CContainer>
            <CRow style="margin-bottom: 20px">
              <CCol md="4"></CCol>
              <CCol md="4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    จากวันที่:
                  </label>
                  <datepicker
                    name="txtDateStart"
                    id="txtDateStart"
                    input-class="form-control bg-white"
                    :required="true"
                    format="yyyy-MM-dd"
                    placeholder=""
                    v-model="dateStart"
                  ></datepicker>
                </div>
              </CCol>
              <CCol md="4">
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    ถึงวันที่:
                  </label>
                  <datepicker
                    name="txtDateEnd"
                    id="txtDateEnd"
                    input-class="form-control bg-white"
                    :required="true"
                    format="yyyy-MM-dd"
                    placeholder=""
                    v-model="dateEnd"
                  ></datepicker>
                </div>
                <div class="text-right">
                  <button class="btn btn-dark" @click="getDateIntervalStat()">
                    ค้นหา
                  </button>
                </div>
              </CCol>
            </CRow>
          </CContainer>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{ enabled: true }"
            :sort-options="{
              enabled: true,
              initialSortBy: { field: 'att_id', type: 'desc' },
            }"
            :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
          >
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'abs_date'">
                <router-link
                  :to="
                    '/statByDate/' +
                    props.row.semester_id +
                    '/' +
                    props.row.abs_date
                  "
                >
                  {{ props.row.abs_date }}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'stud_id'">
                <router-link :to="'/statByStudent/' + props.row.stud_id">
                  {{ props.row.stud_id }}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'subject_name'">
                <router-link :to="'/statBySubject/' + props.row.subject_id">
                  {{ props.row.subject_name }}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'user_fname'">
                <router-link :to="'/statByTeacher/' + props.row.teacher_id">
                  {{ props.row.user_fname }}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'tc_citeria_name'">
                <router-link :to="'/statByReason/' + props.row.tc_citeria_no">
                  {{ props.row.tc_citeria_name }}
                </router-link>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>

              <span v-if="props.column.field == 'actions'">
                <div v-if="props.row.teacher_id == userData.user_id">
                  <button
                    class="btn btn-warning"
                    type="button"
                    @click="openModal(props.row.att_id)"
                  >
                    edit
                  </button>
                  <button
                    class="btn btn-danger"
                    @click="removeAtt(props.row.att_id)"
                    style="margin-top: 0.2rem"
                  >
                    delete
                  </button>
                </div>
              </span>
            </template>
            <div slot="emptystate">
              This will show up when there are no rows
            </div>
          </vue-good-table>

          <CRow style="margin-top: 20px">
            <CCol
              ><div style="text-align: right">
                <button @click="onExport" class="btn btn-secondary">
                  Export
                </button>
                <!-- เพิ่มปุ่ม Export -->
              </div></CCol
            >
          </CRow>
        </CCardBody>
        <div v-else>
          <div class="spinner-border text-warning" role="status"></div>
          <span class="sr-only">Loading...</span>
        </div>
      </CCard>
      <form id="formUpdateAbs" v-on:submit.prevent="updateData" method="POST">
        <CModal
          :show.sync="darkModal"
          :no-close-on-backdrop="true"
          :centered="true"
          title="Modal title 2"
          size="lg"
          color="dark"
        >
          <CRow
            ><!--body-->
            <CCol lg="6">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                #
              </label>
              <input
                type="text"
                name="txtAttId"
                class="form-control"
                readonly
              />
            </CCol>
            <CCol lg="6">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                วันที่
              </label>
              <datepicker
                name="txtDate"
                input-class="form-control"
                :required="true"
                format="yyyy-MM-dd"
                placeholder="วันที่จัดกิจกรรม"
              ></datepicker>
            </CCol>
            <CCol lg="12" v-if="citeriaInfo">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                เกณฑ์การหักคะแนน
              </label>
              <v-select :options="citeriaInfo" @input="setSelected">
                <template #search="{ attributes, events }">
                  <input
                    class="form-control"
                    v-bind="attributes"
                    v-on="events"
                    :required="!selected"
                    placeholder="search"
                  />
                </template>
              </v-select>
            </CCol>
          </CRow>
          <template #header>
            <h6 class="modal-title">
              <h3 class="text-3xl font-semibold">แก้ไข</h3>
            </h6>
            <CButtonClose @click="darkModal = false" class="text-white" />
          </template>
          <template #footer>
            <CButton @click="darkModal = false" color="danger">Discard</CButton>
            <CButton @click="updateData" color="info">Save</CButton>
          </template>
        </CModal>
      </form>
    </CCol>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import TblLateCount from "../views/components/TblLateCount";

export default {
  name: "Dashboard",
  components: { VueGoodTable, Datepicker, vSelect, XLSX, TblLateCount },
  data() {
    return {
      columns: [
        {
          label: "เลขที่",
          field: "att_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "abs_date",
        },
        {
          label: "เลขประจำตัวนักเรียน",
          field: "stud_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ชั้น",
          field: "level_abv_name",
        },
        {
          label: "ห้อง",
          field: "std_class",
          type: "number",
        },
        {
          label: "วิชา/กิจกรรม/สถานที่",
          field: "subject_name",
        },
        {
          label: "ผู้รายงาน",
          field: "user_fname",
        },
        {
          label: "ประเภท",
          field: "tc_citeria_name",
        },
        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      excelRows: null,
      userData: this.$cookies.get("user"),
      citeriaInfo: null,
      selectedCiteria: null,
      responseContent: null,
      darkModal: false,
      nowD: null,
      dateStart: new Date(),
      dateEnd: new Date(),
    };
  },
  mounted() {
    //console.log(this.$store.state.kJs);
    //DATE CONVERTER
    let dObj = new Date();
    this.nowD = this.$store.state.kJs.dateConverter(dObj);

    this.getLateData(
      this.$store.state.kJs.dateConverter(this.dateStart),
      this.$store.state.kJs.dateConverter(this.dateEnd)
    );
  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.excelRows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    get(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    getDateIntervalStat() {
      this.getLateData(
        this.$store.state.kJs.dateConverter(this.dateStart),
        this.$store.state.kJs.dateConverter(this.dateEnd)
      );
    },
    getLateData(dStart, dEnd) {
      this.rows = null;
      let formData = new FormData();
      formData.append("txtAction", "getFlagPunishByDate");
      formData.append("dateStart", dStart);
      formData.append("schoolId", this.userData.user_address);
      formData.append("dateEnd", dEnd);

      //FETCH DATE Stats
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData)
        .then((response) => {
          this.rows = response.data.mainData;
          this.excelRows = response.data.mainData;
          //this.excelRows = response.data.excelData;
          //console.log(response.data);
          //console.log(this.rows);
        });
    },
  },
};
</script>
